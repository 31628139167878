import React from 'react'
import { PagingSource, RasaDataGrid } from '../../generic/rasaDataGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { DateTimeCell } from '../gridcells/datetime';
import { EditableDropdownCell, EditCellProps } from '../../generic/editCell';
import { UpcomingIssue } from '../../shared_server_client/types/schedule';
import { calculateDiffInHours } from '../../shared_server_client/dates';
import { RasaReactComponent } from '../../generic/rasaReactComponent';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { AjaxWrapper, HttpMethod } from '../../generic/ajaxWrapper';
import * as Flash from '../flash';
import { CANCEL_ISSUE_THRESHOLD_IN_HOUR } from './constants';
import { Loading } from '../loading';

export interface UpComingIssueProps {
  communityId: string,
  nextIssue?: UpcomingIssue,
}

export interface UpComingIssueState {
  canCancelIssue: boolean,
  showCancelConfirmationModal: boolean,
}

export class UpComingIssueComponent extends RasaReactComponent<UpComingIssueProps, UpComingIssueState> {

  constructor(props: UpComingIssueProps) {
    super(props, "communityUpcomingIssues")
    this.state = {
      ...this.state,
      canCancelIssue: false,
      showCancelConfirmationModal: false
    }
  }

  public render() {
    return <>
      <div className="header-container">
        <div className="standard-header">
          <div className="titles-and-description-container">
            <h6 className="title">
              SCHEDULE
            </h6>
            <div className="sub-title">
              You’ve got one coming up
            </div>
          </div>
        </div>
      </div>
      <div>
        <RasaDataGrid
          entityName=""
          datasetName={this.entityName}
          editDefaultState={true}
          sortable={false}
          pageable={false}
          pagingSource={PagingSource.local}
          onDataReceived={this.dataReceived}
          data={[]}>
          <GridColumn title="Send At" field="date" cell={DateTimeCell}/>
          <GridColumn title="Cutoff" field="cutoff" cell={DateTimeCell}/>
          <GridColumn title="Create Date" field="created" cell={DateTimeCell}/>
          <GridColumn title="# Sent" field="num_sent" width={200}/>
          <GridColumn cell={this.actionCell}/>
        </RasaDataGrid>
      </div>
      {this.renderCancelConfirmationModal()}
    </>
  }

  private dataReceived = (data) => {
    if (data.data && data.data.length > 0) {
      const upcomingIssue: UpcomingIssue = data.data[0]
      const diffInHours = calculateDiffInHours(upcomingIssue.date, null)
      this.setState({
        canCancelIssue: diffInHours > CANCEL_ISSUE_THRESHOLD_IN_HOUR
      })
    }
  }

  private actionCell = (props: EditCellProps) => {
    return this.state.canCancelIssue ? <EditableDropdownCell
      {...props}
      canArchive={true}
      archiveLabel={'Cancel'}
      onArchive={this.onCancelRecord}
    /> : null
  }

  private onCancelRecord = (props, e) => {
    this.setState({
      showCancelConfirmationModal: true
    })
  }

  private cancelRecord = (e) => {
    this.setState({
      isSaving: true,
    })
    const url: string = AjaxWrapper.getServerUrl() + `/${this.props.communityId}/cancel-issue`
    AjaxWrapper.ajax(url, HttpMethod.POST, { issue_id: this.props.nextIssue.id })
      .then((response) => {
        this.setState({
          isSaving: false,
          showCancelConfirmationModal: false,
        })
        window.location.reload()
      }).catch((error) => {
      this.context.store.dispatch(Flash.showFlashError('Failed to cancel issue'))
      this.setState({
        isSaving: false,
        showCancelConfirmationModal: false
      })
    })
  }

  private closeCancelConfirmationModal() {
    this.setState({
      showCancelConfirmationModal: false,
    })
  }

  private renderCancelConfirmationModal(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.showCancelConfirmationModal}>
          <ModalBody className="delete-modal">
            <div>
              <h4>Are You Sure?</h4>
              {this.state.isSaving ? <Loading size="64" /> :
                <p>Are you sure you want to cancel this? <br/>
                  Once you cancel it, you cannot change it.</p>
              }
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={(e) => this.cancelRecord(e)}>
              Yes
            </Button>
            <Button onClick={() => this.closeCancelConfirmationModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
