import { InitialScheduledContentModalState } from '../constants'
import { ScheduleContentModalProps } from './base'
import { ScheduledArticleBaseComponent } from './scheduleArticleBaseComponent'

export class ScheduledArticleModalComponent extends ScheduledArticleBaseComponent {
  public static key: string = 'scheduledArticleModal'

  constructor(props: ScheduleContentModalProps) {
    super(props, ScheduledArticleModalComponent.key)
    this.state = InitialScheduledContentModalState
  }

  protected getCloseText(data: any): string {
    return 'Save & More'
  }

  protected secondActionDisabled(data: any): boolean {
    return this.saveDisabled(data)
  }

  protected save(data: any) {
    this.setState({isSaving: true})
    this.doSave(data)
      .then(() => {
        if (!this.state.addMore) {
          this.props.saveModal(this.key, this.props.data)
        }
        this.setState({
          isSaving: false,
          addMore: false
        })

      })
      .catch(() => this.setState({ isSaving: false }))
  }

  protected close(data: any) {
    this.setState({
      addMore: true,
    }, () => this.save(data))
  }
}
