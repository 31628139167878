export const SCHEDULE_STARTED_MODAL = 'SCHEDULE_STARTED_MODAL'
export const PRE_PROCESS_EDIT_MODAL = 'PRE_PROCESS_EDIT_MODAL'
export const MIN_CUTOFF_OFFSET = 2
export const MAX_CUTOFF_OFFSET = 72
export const TIME_FORMAT = 'HH:mm'
export const UNSUCCESSFUL_MANUAL_SEND = 'Oops something went wrong. your newsletter was not scheduled. Try again or contact Customer Success'
export const SUCCESSFUL_MANUAL_SEND = 'Your manual send is set. You can schedule your next newsletter after this time.'
export const ISSUE_LOOKBACK_DAYS_FOR_SEND = 6
export const SEND_SCHEDULE_MESSAGE = "You can now proceed with sending the schedule."
export const CANCEL_ISSUE_THRESHOLD_IN_HOUR = 1
export const MANUAL_SCHEDULE_GENERATE_DELAY_MILLISECONDS = 20000
