import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { GridCellProps, GridColumn as Column, GridRowClickEvent } from '@progress/kendo-react-grid'
import { canAccessSegment } from 'app/utils'
import { ClickOutside } from 'components/click-outside/component'
import { ActiveCell, DateTimeCell, ImageCell } from 'components/content-pool/kendocells'
import { DropdownComponent, DropdownOption, OnChangeEvent } from 'components/dropdown/component'
import { GeneratedSummaryModalComponent, GeneratedTextModalComponent } from 'components/generate-text'
import { HeaderComponent } from 'components/header/component'
import { CarrotDown } from 'components/icons/carrot-down'
import { CarrotUp } from 'components/icons/carrot-up'
import { Loading } from 'components/loading'
import { MenuItem, TabMenu } from 'components/tab-menu/component'
import { RasaContext } from 'context'
import { addDays, addMonths, endOfMonth, endOfWeek, format, parse, startOfMonth, startOfWeek, subMonths } from 'date-fns'
import { addWeeks, subWeeks } from 'date-fns/esm'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { EditableDropdownCell, EditCellProps } from 'generic/editCell'
import * as GenericRedux from 'generic/genericRedux'
import { PagingSource, RasaDataGrid } from 'generic/rasaDataGrid'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isTruthy } from 'generic/utility'
import * as GA from 'google-analytics'
import { capitalize, groupBy, isEmpty } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import { DATEPICKER_DATE_FORMAT, Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { ModalSize } from 'shared/modals/constants'
import { ADD, DEFAULT_COMMUNITY_PARTNER_CODES, DEFAULT_TIMEZONE, IMAGE, ScheduledContentTypes, TEXT } from 'shared_server_client/constants'
import { formatDateForES, resetToStartOfDay, resetToStartOfDayZonedToIso, startOfDay } from 'shared_server_client/dates'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { getImageLocationOptions, getTextLocationOptions } from 'shared_server_client/utils'
import 'tui-calendar/dist/tui-calendar.css'
import 'tui-time-picker/dist/tui-time-picker.css'
import { UpgradeComponent } from '../../upgrade/component';
import { SCHEDULE_CONTENT_ADDITIONAL_FEATURES } from '../../upgrade/constants';
import { TextPreviewCell } from '../kendocells/textPreviewCell'
import './_styles.scss'
import {CUSTOM_DATE_RANGE_KEY, DateRangeFilter, FEATURE_TYPE, FORMAT_TYPE_EVENT, ImageDisplayType} from './constants'
import { ScheduledArticleModalComponent } from './modals/scheduledArticle'
import { ScheduledImageModalComponent } from './modals/scheduledImage'
import { ScheduledTextModalComponent } from './modals/scheduledText'
import { SponsoredArticleModalComponent } from './modals/sponsoredArticle'
import { ImageGalleryModal } from '../../common/image-gallery/modal';
import { EmailLayout } from './modals/base';
import * as Flash from '../../flash'
import { AddEditEventModal } from 'components/content-pool/addEditEventModal'
import { ModalActionType } from "../../../shared/modals";

const addDash = (locationValue: string, prefix = 'text'): string => {
  if (locationValue && locationValue.toLowerCase().startsWith(prefix)) {
    return `${capitalize(prefix)} ${locationValue.toLocaleLowerCase().replace(prefix, '')}`
  } else {
    return locationValue || ''
  }
}

export const DateRangesAsDropdownOptions: DropdownOption[] = [
  { key: 1, value: DateRangeFilter.YESTERDAY,
    description: DateRangeFilter.YESTERDAY },
  { key: 2, value: DateRangeFilter.TODAY,
    description: DateRangeFilter.TODAY },
  { key: 3, value: DateRangeFilter.LAST_SEVEN_DAYS,
    description: DateRangeFilter.LAST_SEVEN_DAYS },
  { key: 4, value: DateRangeFilter.LAST_WEEK,
    description: DateRangeFilter.LAST_WEEK },
  { key: 5, value: DateRangeFilter.LAST_30_DAYS,
    description: DateRangeFilter.LAST_30_DAYS },
  { key: 6, value: DateRangeFilter.LAST_MONTH,
    description: DateRangeFilter.LAST_MONTH },
  { key: 7, value: DateRangeFilter.THIS_WEEK,
    description: DateRangeFilter.THIS_WEEK },
  { key: 8, value: DateRangeFilter.NEXT_WEEK,
    description: DateRangeFilter.NEXT_WEEK },
  { key: 9, value: DateRangeFilter.NEXT_MONTH,
    description: DateRangeFilter.NEXT_MONTH },
  { key: 10, value: DateRangeFilter.CUSTOM_DATE_RANGE,
    description: DateRangeFilter.CUSTOM_DATE_RANGE },
]

interface ScheduledContentComponentState {
  content: any[]
  dropdown: boolean
  endDate: Date
  isLoading: boolean
  isSuperUser: boolean
  forceReload: boolean
  hasEventAccess: boolean
  hasFeatureAccess: boolean
  hasGenerativeAIAccess: boolean
  hasSegmentAccess: boolean
  hasSponsoredAccess: boolean
  selectedItem: any
  startDate: Date
  tempEndDate: Date
  tempStartDate: Date
  selectedDateRange: DropdownOption
  selectedSection: string
}

enum ContentSections {
  active = 'content_active',
  inactive = 'content_inactive',
}

interface SCProps {
  [key: string]: string
}
type ScheduledContentComponentProps = GenericRedux.AllComponentPropsWithModal<any> & SCProps

class ScheduledContentComponent extends RasaReactComponent<
  ScheduledContentComponentProps,
  ScheduledContentComponentState
> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private communityId: string = null
  private timezone: string = null
  private textLocationOptions: any[]
  private imageLocationOptions: any[]
  private emailLayout: EmailLayout

  constructor(props) {
    super(props, 'scheduledContent')
    this.state = {
      content: [],
      dropdown: false,
      endDate: null,
      forceReload: false,
      hasEventAccess: false,
      hasFeatureAccess: false,
      hasGenerativeAIAccess: false,
      hasSegmentAccess: false,
      hasSponsoredAccess: false,
      isDirty: false,
      isLoading: true,
      isSaving: false,
      isSuperUser: false,
      selectedItem: null,
      startDate: null,
      tempEndDate: new Date(),
      tempStartDate: new Date(),
      selectedDateRange: DateRangesAsDropdownOptions[6], // This Week
      selectedSection: ContentSections.active,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.params),
      this.sharedStore.getValue(SharedKeys.role),
    ]).then(([activeCommunity, person, params, role]) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      const sponsoredFeature: any = activeCommunity.billingInfo.currentPlan.details.find((x) =>
        x.billing_plan_code === BillingPlanDetailCode.MAX_SPONSORED_POSTS)
      const generativeFeature: any = activeCommunity.billingInfo.currentPlan.details.find((x) =>
        x.billing_plan_code === BillingPlanDetailCode.GENERATIVE_AI)
      const {action, endDate, location, startDate, type} = params
      this.communityId = activeCommunity.communityId
      this.timezone = activeCommunity.data && activeCommunity.data.company_time_zone
        ? activeCommunity.data.company_time_zone
        : DEFAULT_TIMEZONE
      const tempEndDate = parse(
        endDate ? endDate : format(endOfWeek(new Date()), DATEPICKER_DATE_FORMAT),
        DATEPICKER_DATE_FORMAT,
        endOfWeek(new Date()),
      )
      const tempStartDate = parse(
        startDate ? startDate : format(startOfWeek(new Date(), {weekStartsOn: 1}), DATEPICKER_DATE_FORMAT),
        DATEPICKER_DATE_FORMAT,
        startOfWeek(new Date()),
      )
      this.setState({
        hasEventAccess: avlFeatures.indexOf(BillingPlanDetailCode.EVENTS) > -1,
        hasFeatureAccess: avlFeatures.indexOf(BillingPlanDetailCode.SCHEDULED_CONTENT) > -1,
        hasSponsoredAccess: sponsoredFeature && sponsoredFeature.limit_val !== 0,
        hasGenerativeAIAccess: generativeFeature && generativeFeature.limit_val !== 0,
        endDate: tempEndDate,
        isSuperUser: role === Roles.super_admin,
        startDate: tempStartDate,
        tempEndDate,
        tempStartDate,
      })
      canAccessSegment(avlFeatures, activeCommunity.communityId).then((canAccess) => {
        this.setState({hasSegmentAccess: canAccess})
      })
      if (!activeCommunity.communityInfo.data.email_layouts) {
        this.setState({
          isLoading: false
        })
        this.context.store.dispatch(Flash.showFlashError('\'Oops! Something wrong. Please try again.'))
      } else {
        const activeLayoutId = activeCommunity.communityInfo.data.email_layouts.filter((s: any) => s.is_active)[0].id
        this.context.entityMetadata.getEntityObject(
          'email_layout',
          activeCommunity.communityId,
          activeLayoutId,
        )
          .then((emailLayoutEntityObject: BaseClientEntity) => {
            this.emailLayout = emailLayoutEntityObject.data
            this.emailLayout.id = activeLayoutId
            Promise.all([
              getTextLocationOptions(emailLayoutEntityObject.data),
              getImageLocationOptions(emailLayoutEntityObject.data),
            ])
              .then(([textLocationOptions, imageLocationOptions]) => {
                this.textLocationOptions = textLocationOptions
                this.imageLocationOptions = imageLocationOptions
                this.setState({isLoading: false})
              })
          })
        if (action === ADD) {
          const contentType = type === IMAGE
            ? ScheduledContentTypes.image
            : type === TEXT
              ? ScheduledContentTypes.text
              : ScheduledContentTypes.article
          this.props.openModal(`${contentType}Modal`, {
            communityId: this.communityId,
            contentType,
            imageDisplayType: contentType === ScheduledContentTypes.image ?
              ImageDisplayType.DYNAMIC : '',
            location: {key: location, value: location},
            timezone: this.timezone,
          })
        }
      }
    })
  }

  public render = () => {
    if (this.state.isLoading) {
      return <Loading size="64" />
    } else {
      if (!this.state.hasFeatureAccess) {
        return <UpgradeComponent
          source={GA.UpgradeSource.ScheduledContent}
          features={SCHEDULE_CONTENT_ADDITIONAL_FEATURES}
          description="Unlock more sources and features when you upgrade your plan" />
      } else {
        return <div className="scheduled-content">
          <div>
            { this.renderHiddenModals() }
            <div className="top-row">
              <HeaderComponent
                title={'SCHEDULE'}
                subTitle={'Scheduled Content'}
                description={[
                  'Select and schedule content to be included in your newsletter on specific dates.',
                ]}
              />
              <ClickOutside
                handleClickOutside={() =>
                  this.state.dropdown
                    ? this.setState({ dropdown: !this.state.dropdown })
                    : null}>
                <div className="dropdown-container">
                  <Button
                    onClick={() =>
                      this.setState({ dropdown: !this.state.dropdown })}
                    className="dropdown-button">
                    Add New &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.dropdown ? (
                      <CarrotUp className="carrot" />
                    ) : (
                      <CarrotDown className="carrot" />
                    )}
                  </Button>
                  { this.renderModalOptions() }

                </div>
              </ClickOutside>
            </div>
        <div className="dropdown">
          <div className="grid-container">
            <DropdownComponent data={DateRangesAsDropdownOptions}
                                selected={this.state.selectedDateRange.key}
                                onChange={this.dateChanged}/>
          </div>
        </div>
        <div className="date-range-picker">
          {this.state.selectedDateRange.key === CUSTOM_DATE_RANGE_KEY ?
          <DateRangePicker onChange={this.createCustomDate} /> : null}
        </div>
          </div>
          <div>
            <TabMenu menus={this.contentMenus()}
                      selectedItemKey={this.state.selectedSection}
                      onSelect={(item: MenuItem) => {
                        this.setState({
                          selectedSection: item.key,
                        })
                      }}/>
            </div>
          <RasaDataGrid
            // Rasa Properties for entity, datasetName etc
            entityName="scheduledContent"
            datasetName="scheduledContent"
            datasetParams={[
              { param: 'startDate', value: startOfDay(this.state.startDate, this.timezone).toISOString()},
              { param: 'endDate', value: startOfDay(addDays(this.state.endDate, 1), this.timezone).toISOString()},
            ]}
            editDefaultState={true}
            forceReload={this.state.forceReload}
            onDataReceived={this.dataReceived}
            onRowClick={(p: GridRowClickEvent) =>  this.editScheduledContentItem(p.dataItem)}
            gridFilter={this.filterData}
            // events
            sortable={true}
            pageable={false}
            pagingSource={PagingSource.local}
            data={[]}
          >
            <Column field="is_active" title="Active" cell={this.ContentActiveCell} width="80%" />
            <Column field="hosted_image_url" title="Preview" width="200%" cell={this.previewCell}/>
            <Column field="content_type" title="Type" cell={this.locationCell} width="100%"/>
            <Column field="title" title="Title" width="200%" />
            <Column field="start_date" title="Start Date" cell={DateTimeCell} width="200%"/>
            <Column field="end_date" title="End Date" cell={DateTimeCell} width="200%"/>
            <Column field="section_name" title="Section" cell={this.sectionCell} width="100%"/>
            {this.state.hasSegmentAccess && <Column field="community_partner_code" title="Segment" width="100%" />}
            <Column field="rank" title="Rank" cell={this.rankCell} width="100%"/>
            <Column cell={this.scheduleEditCell} width={50} />
          </RasaDataGrid>
        </div>
      }
    }
  }

  public dateChanged = (e: OnChangeEvent) => {
    this.setState({
      selectedDateRange: e.selected,
    })
    switch (e.selected.value) {
      case DateRangeFilter.YESTERDAY:
        return this.setState({endDate: this.getPastDate(1), startDate: this.getPastDate(1)})
      case DateRangeFilter.TODAY:
        return this.setState({endDate: this.getPastDate(0), startDate: this.getPastDate(0)})
      case DateRangeFilter.LAST_SEVEN_DAYS:
        return this.setState({endDate: this.getPastDate(0), startDate: this.getPastDate(7)})
      case DateRangeFilter.LAST_WEEK:
        const firstDayOfLastWeek = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })
        const lastDayOfLastWeek = endOfWeek(subWeeks(new Date(), 1))

        return this.setState({endDate: lastDayOfLastWeek, startDate: firstDayOfLastWeek})
      case DateRangeFilter.LAST_30_DAYS:
        return this.setState({endDate: this.getPastDate(0), startDate: this.getPastDate(30)})
      case DateRangeFilter.LAST_MONTH:
        const firstDayOfLastMonth = startOfMonth(subMonths(new Date(), 1))
        const lastDayOfLastMonth = endOfMonth(subMonths(new Date(), 1))
        return this.setState({endDate: lastDayOfLastMonth, startDate: firstDayOfLastMonth})
      case DateRangeFilter.THIS_WEEK:
        const firstDayOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 })
        const lastDayOfThisWeek = endOfWeek(new Date())
        return this.setState({endDate: lastDayOfThisWeek, startDate: firstDayOfThisWeek})
      case DateRangeFilter.NEXT_WEEK:
        const firstDayOfNextWeek = startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 })
        const lastDayOfNextWeek = endOfWeek(addWeeks(new Date(), 1))
        return this.setState({endDate: lastDayOfNextWeek, startDate: firstDayOfNextWeek})
      case DateRangeFilter.NEXT_MONTH:
        const firstDayOfNextMonth = startOfMonth(addMonths(new Date(), 1))
        const lastDayOfNextMonth = endOfMonth(addMonths(new Date(), 1))
        return this.setState({endDate: lastDayOfNextMonth, startDate: firstDayOfNextMonth})
    }
  }

  public createCustomDate = (e) => {
    if (e.value.start === null || e.value.end === null) {
      return
    } else {
      const customRange = `${formatDateForES(e.value.start)}|${formatDateForES(addDays(e.value.end, 1))}`
      this.setState({
        selectedDateRange: {
          ...this.state.selectedDateRange,
          value: customRange,
         },
      })
      return this.setState({endDate: e.value.end, startDate: e.value.start})
    }
  }

  private renderModalOptions = () => {
    if (!this.state.dropdown ) {
      return null
    }

    const scheduledTextData = {
      communityId: this.communityId,
      content_type: ScheduledContentTypes.text,
      timezone: this.timezone,
      location: this.textLocationOptions[0],
      emailLayout: this.emailLayout,
    }

    return <div className="dropdown-content">
        <a
          className="drop-link"
          onClick={(e) => {
            e.preventDefault()
              this.props.openModal(ScheduledImageModalComponent.key, {
                communityId: this.communityId,
                timezone: this.timezone,
                content_type: ScheduledContentTypes.image,
                location: this.imageLocationOptions[0],
                emailLayout: this.emailLayout,
                imageAltText: this.emailLayout.banner_alt_text,
              })}}
          href="#">
          Image
        </a>
        <a
          className="drop-link"
          onClick={(e) => {
            e.preventDefault()
              this.props.openModal(ScheduledTextModalComponent.key, scheduledTextData)}}
          href="#">
          Text
        </a>
        {this.state.hasGenerativeAIAccess &&
        <a
          className="drop-link"
          onClick={(e) => {
            e.preventDefault()
              this.props.openModal(GeneratedTextModalComponent.key, scheduledTextData)}}
          href="#">
          Generate Text
        </a>}
        <a
          className="drop-link"
          onClick={(e) => {
            e.preventDefault()
              this.props.openModal(ScheduledArticleModalComponent.key,
                {communityId: this.communityId,
                rank: 1,
                content_type: ScheduledContentTypes.article,
                timezone: this.timezone})}}
          href="#">
          Article
        </a>
        {this.state.hasSponsoredAccess &&
        <a
          className="drop-link"
          onClick={(e) => {
            e.preventDefault()
              this.props.openModal(SponsoredArticleModalComponent.key,
                {communityId: this.communityId,
                featureType: FEATURE_TYPE.SPONSORED_POST,
                rank: 1,
                content_type: ScheduledContentTypes.article,
                timezone: this.timezone})}}
          href="#">
          Sponsored
        </a>}
        {this.state.hasEventAccess &&
        <a
          className="drop-link"
          onClick={(e) => {
            e.preventDefault()
              this.props.openModal(AddEditEventModal.key,
                {communityId: this.communityId})}}
          href="#">
          Event
        </a>}
      </div>
  }

  private renderHiddenModals = () => {
    return <div>
      <ScheduledImageModalComponent
        data={this.props.modals}
        closeModal={this.props.closeModal}
        emailLayout={this.emailLayout}
        forceReload={this.forceReload}
        locationOptions={this.imageLocationOptions}
        saveModal={this.props.saveModal}
        updateModal={this.props.updateModal}
        openModal={this.props.openModal}
        size={ModalSize.Large}/>
    <ScheduledTextModalComponent
        data={this.props.modals}
        closeModal={this.props.closeModal}
        emailLayout={this.emailLayout}
        forceReload={this.forceReload}
        locationOptions={this.textLocationOptions}
        saveModal={this.props.saveModal}
        updateModal={this.props.updateModal}
        size={ModalSize.Large}/>
    <ScheduledArticleModalComponent
        allowSegment={true}
        defaultSegmentCode={DEFAULT_COMMUNITY_PARTNER_CODES.ALL}
        isScheduled={true}
        data={this.props.modals}
        closeModal={this.props.closeModal}
        forceReload={this.forceReload}
        saveModal={this.props.saveModal}
        updateModal={this.props.updateModal}
        openModal={this.props.openModal}
        size={ModalSize.Large}
        secondAction={ModalActionType.BUTTON}
    />
    <SponsoredArticleModalComponent
        allowSegment={true}
        defaultSegmentCode={DEFAULT_COMMUNITY_PARTNER_CODES.ALL}
        isScheduled={true}
        featureType={FEATURE_TYPE.SPONSORED_POST}
        data={this.props.modals}
        closeModal={this.props.closeModal}
        forceReload={this.forceReload}
        saveModal={this.props.saveModal}
        updateModal={this.props.updateModal}
        size={ModalSize.Large}/>
    {this.state.hasEventAccess && <AddEditEventModal
        data={this.props.modals}
        allowSegment={false}
        defaultSegmentCode={DEFAULT_COMMUNITY_PARTNER_CODES.ALL}
        allowGeneratingViaChatGPT={true}
        isScheduled={true}
        forceReload={this.forceReload}
        updateModal={this.props.updateModal}
        openModal={this.props.openModal}
        saveModal={this.props.saveModal}
        closeModal={this.props.closeModal}
        title="Event Article Webinar"
        size={ModalSize.Large} />}
    {this.state.hasGenerativeAIAccess && <GeneratedTextModalComponent
        data={this.props.modals}
        closeModal={this.props.closeModal}
        saveModal={(key, data) => {
          this.props.closeModal(key)
          return this.props.openModal(ScheduledTextModalComponent.key, data)
        }}
        saveText="Schedule It!"
        updateModal={this.props.updateModal}
        size={ModalSize.Large}/>
      }
    </div>
  }

  private getPastDate = (numOfDays: number): Date =>  {
    const date = new Date();
    date.setDate(date.getDate() - numOfDays);
    return date
  }
  private contentMenus = (): MenuItem[] => {
    const contentData = this.state.content.length > 0 ? this.state.content : []
    const scheduledContents = groupBy(contentData, this.isActiveContent)
    const byActive = groupBy(scheduledContents.true, 'is_active')
    const menus = [
      {
        name: 'Active (' + (byActive[1] || []).length + ')',
        key: ContentSections.active,
        component: null,
      },
      {
        name: 'Inactive (' + (scheduledContents.false || []).length + ')',
        key: ContentSections.inactive,
        component: null,
      },
    ]
    return menus
  }

  private scheduleEditCell = (props: EditCellProps) => {
    const { dataItem } = props
    switch (dataItem.content_type) {
      case ScheduledContentTypes.article:
      case ScheduledContentTypes.image:
      case ScheduledContentTypes.sponsored:
      case ScheduledContentTypes.text:
        return <EditableDropdownCell {...props}
        onClone={this.cloneCell}
        canClone={true} />
      default:
        return <td></td>
    }
  }

  private locationCell = (props) => {
    const { dataItem } = props
    if (!isEmpty(dataItem)) {
      const location = dataItem.content_type === ScheduledContentTypes.image
      ? dataItem.name : dataItem.content_type === ScheduledContentTypes.text
        ? addDash(dataItem.lead_text_type)
        :
        dataItem.content_type === ScheduledContentTypes.sponsored ?
        `Sponsored ${dataItem.rank}` :
        dataItem.format_type === FORMAT_TYPE_EVENT ? 'Event' : 'Article'
      return <td>{location}</td>
    } else {
      return null
    }
  }

  private previewCell = (props) => {
    const { dataItem } = props
    switch (dataItem.content_type) {
      case ScheduledContentTypes.article:
      case ScheduledContentTypes.sponsored:
      case ScheduledContentTypes.image:
        return <ImageCell {...props} onRowClick={() => this.editScheduledContentItem(dataItem)} skipResize={true} />
      case ScheduledContentTypes.text:
        return <TextPreviewCell {...props} onRowClick={() => this.editScheduledContentItem(dataItem)}/>
      default:
        return null
    }
  }

  private rankCell = (props) => {
    const { dataItem } = props
    switch (dataItem.content_type) {
      case ScheduledContentTypes.article:
      case ScheduledContentTypes.sponsored:
        return <td>{dataItem.rank}</td>
      default:
        return <td></td>
    }
  }

  private modalName = (dataItem: any) => {
    return dataItem.format_type === 'Event' ? 'addEditEventModal' : `${dataItem.content_type}Modal`
  }

  private cloneCell = (clone: any) => {
    const { dataItem } = clone
    this.setState({ selectedItem: dataItem })
    this.props.openModal(this.modalName(dataItem), {
      ...this.normalizeItem(dataItem),
      id: null,
      typeId: null,
      post_subscription_id: null,
      startDate: null,
      endDate: null,
      communityId: this.communityId,
      timezone: this.timezone,
      isCloneItem: true,
      featureType: dataItem.feature_type,
    })
  }

  private sectionCell = (props) => {
    const { dataItem } = props
    switch (dataItem.content_type) {
      case ScheduledContentTypes.article:
        return <td>{dataItem.section_name}</td>
      default:
        return <td></td>
    }
  }

  private ContentActiveCell = (props: GridCellProps) => {
    return <ActiveCell fieldName="is_active" {...props}/>
  }

  private normalizeItem = (scheduledContentItem: any) => {
    const contentType = scheduledContentItem.content_type === ScheduledContentTypes.sponsored ?
      ScheduledContentTypes.article : scheduledContentItem.content_type
    const normalizedItem: any = {
      ...scheduledContentItem,
      contentType,
      contentCategory: scheduledContentItem.content_category,
      endDate: scheduledContentItem.end_date ? resetToStartOfDay(scheduledContentItem.end_date) : null,
      startDate: scheduledContentItem.start_date ? resetToStartOfDay(scheduledContentItem.start_date) : null,
      typeId: scheduledContentItem.typeId || `${contentType}-${scheduledContentItem.id}`,
    }
    if (contentType === ScheduledContentTypes.image) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      normalizedItem.linkUrl = scheduledContentItem.url,
      normalizedItem.imageUrl = scheduledContentItem.hosted_image_url,
      normalizedItem.imageTitle = scheduledContentItem.title,
      normalizedItem.imageAltText = scheduledContentItem.image_alt_text,
      normalizedItem.imageDisplayType = scheduledContentItem.image_display_type,
      normalizedItem.location = {key: scheduledContentItem.name, value: scheduledContentItem.name}
      normalizedItem.emailLayout = this.emailLayout
    } else if (contentType === ScheduledContentTypes.text) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      normalizedItem.lead_text_type = scheduledContentItem.lead_text_type,
      normalizedItem.is_edited = scheduledContentItem.is_edited,
      normalizedItem.styles = scheduledContentItem.styles
      normalizedItem.location = {
        key: addDash(scheduledContentItem.lead_text_type),
        value: scheduledContentItem.lead_text_type,
      }
      normalizedItem.description = scheduledContentItem.description
      normalizedItem.emailLayout = this.emailLayout
    } else {
      normalizedItem.publisher = scheduledContentItem.site_name
      normalizedItem.featureType = scheduledContentItem.feature_type
      normalizedItem.image = scheduledContentItem.hosted_image_url
      normalizedItem.rank = scheduledContentItem.rank
      normalizedItem.customTags = scheduledContentItem.custom_tags
      normalizedItem.nlpTags = scheduledContentItem.nlp_tags
      normalizedItem.sourceTags = scheduledContentItem.source_tags
      if (scheduledContentItem.hosted_image_url) {
        normalizedItem.hostedImage = {
          url: scheduledContentItem.hosted_image_url,
          width: scheduledContentItem.image_width,
          height: scheduledContentItem.image_height,
        }
      }
    }
    return normalizedItem
  }

  private editScheduledContentItem = (scheduledContentItem: any) => {
    this.setState({ selectedItem: scheduledContentItem })
    this.props.openModal(
      this.modalName(scheduledContentItem),
      {
        ...this.normalizeItem(scheduledContentItem),
        featureType: scheduledContentItem.feature_type,
        communityId:
        this.communityId,
        timezone: this.timezone
      })
  }

  private forceReload = () => {
    this.setState({forceReload: true}, () => this.setState({forceReload: false}))
  }

  private isActiveContent(article: any): boolean {
    return isTruthy(article.is_active)
  }

  private filterData = (a: any): boolean => {
    const stateArticle = this.state.content.filter((x) => x.id === a.id)[0] || a

    if ((this.state.selectedSection === ContentSections.active) &&
      (!isTruthy(stateArticle.is_active))) {
      return false
    } else if ((this.state.selectedSection === ContentSections.inactive) &&
      (isTruthy(stateArticle.is_active))) {
      return false
    } else {
      return true
    }
  }

  private dataReceived = (data) => {
    if (data.data) {
      data.data = data.data.map((sc) => ({
        ...sc,
        start_date: resetToStartOfDayZonedToIso(sc.start_date, this.timezone),
        end_date: resetToStartOfDayZonedToIso(sc.end_date, this.timezone),
      }))
      this.setState({
        content: data.data,
      })
    }
  }
}

export const ScheduledContent = GenericRedux.registerNewComponentWithModals<any>(
  ScheduledContentComponent,
  'scheduled_content',
  [
    GeneratedTextModalComponent.key,
    GeneratedSummaryModalComponent.key,
    ScheduledArticleModalComponent.key,
    SponsoredArticleModalComponent.key,
    ScheduledImageModalComponent.key,
    ScheduledTextModalComponent.key,
    ImageGalleryModal.key,
    AddEditEventModal.key,
  ],
  {},
)
