import React from 'react'
import * as Modals from 'shared/modals'
import { scheduleSformatDate } from 'shared_server_client/dates'
import * as Constants from './constants'
import { Loading } from '../loading';

export class ScheduleStartedModal extends Modals.GenericModalComponent
  <Modals.ModalComponentProps, Modals.ModalComponentState> {
  public static key: string = 'scheduleStart'
  public static defaultProps = {
    secondAction: Modals.ModalActionType.LINK,
  }
  constructor(props: Modals.ModalComponentProps) {
    super(props, ScheduleStartedModal.key)
  }
  protected renderChildren(data: any) {
    // data is a Schedule, from the open.  Do with it what we choose.
    if (this.props.modalType === Constants.PRE_PROCESS_EDIT_MODAL) {
      return this.preProcessWindowModal(data)
    } else {
      return this.scheduleStartedModal(data)
    }
  }

  private scheduleStartedModal(data: any) {
    return (
      data.nextIssue && (
        <span>
          Your first email will go out on{' '}
          <strong>{scheduleSformatDate(data.nextIssue.date, data.timezone)}</strong>
          .
          <br />
          All content will be available for review after{' '}
          {scheduleSformatDate(data.nextIssue.cutoff, data.timezone, false)}.
          <br />
          You can't make changes to your schedule or content selection after{' '}
          {scheduleSformatDate(
            data.nextIssue.production_cutoff,
            data.timezone,
            false,
          )}
        </span>
      )
    )
  }

  private preProcessWindowModal(data: any) {
    return (
      <div className="preprocess-modal-text">
        <p className="primary">
          The delivery time you chose is within the hour! We'll do our best to
          deliver it then but you may experience a delay.
        </p>
        <p className="secondary">
          To ensure your newsletter is delivered at a certain time, schedule it
          at least 1 hour in advance.
        </p>
      </div>
    )
  }
}

interface ManualSendModalModalProps extends Modals.ModalComponentProps {
  onSave: () => any
}
export class ManualSendModal extends Modals.GenericModalComponent
  <ManualSendModalModalProps, Modals.ModalComponentState> {
  public static key: string = 'manualSendModal'
  public static defaultProps = {
    closeButtonText: 'Close',
    saveText: 'Confirm',
    secondAction: Modals.ModalActionType.BUTTON,
    title: 'Confirm Manual Send',
  }
  constructor(props: ManualSendModalModalProps) {
    super(props, ManualSendModal.key)
  }
  protected renderChildren(data: any) {
    return this.state.isSaving ? <Loading size="64" /> :
      <span>
        Once you schedule this email, you cannot change it or cancel it. <br/><br/>
        You can schedule another email after this one sends.
      </span>
  }

  protected save(data: any) {
    this.setState({
      isSaving: true,
    })
    return this.props.onSave().then(() => {})
  }

}
